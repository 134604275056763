@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --font-xxl: 5rem;
    --font-xl: 3rem;
    --font-600: 1.5rem;
    --font-500: 1.125rem;
    --font-400: 1rem;
}

@media  screen and (min-width: 500px) {
    :root {
        --font-xxl: 5rem;
        --font-xl: 3rem;
        --font-600: 1.5rem;
        --font-500: 1.125rem;
        --font-400: 1rem;
    }
}

body, .content {
    overflow-x: hidden;
    font-family: arial, sans-serif;
}

.biggest {
    /*font-size: var(--font-xl);*/
}

.mb_font_to{
    font-family: Roboto;
}
.mb-background-image{
    background:url('./../public/images/35A8177-1024x566.jpg');
    background-size: cover;
    transform: scale(1.2);
}
.mb-overlay{
    background-color: transparent;
    background-image: linear-gradient(180deg, rgba(69, 69, 69, 0) 32%, #000000 100%);
    opacity: 0.67;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
}
.mb-shadow-hover{
    box-shadow: 1px 1px 12px #3a4c4d;
}
.mb-font-makita{
    font-family: 'SF Pro Display';
}
.mb-shadow-banner{
    box-shadow: 15px 5px 40px #39888A;
}
.mb-shadow-makita{
    box-shadow: 2px 2px 15px #414444;
}
.mb-shadow-makita-text{
    text-shadow: 0px 0px 7px #3a888a;
}
/* Active navBar */
.active-navBar{
    color: #3a888a;
}
.active-navBar-toggle{
    background: white;
    color: #3a888a;
}

/* Animate image scale*/
.elementor-animation-bounce-out:hover {
    transform: scale(0.8);
    transition-timing-function: cubic-bezier(0.47,2.02,.31,-.36);
}
.elementor-widget-image img {
    vertical-align: middle;
    display: inline-block;
}
.elementor-animation-bounce-out {
    transition-duration: .5s;
}

.fa-volume-up:hover {
    animation-name: fa-beat;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}

.load-bar { width: 0% }

.load-bar.active {
    animation-name: load-bar;
    animation-duration: 2s;
    width: 100%;
}

/* Fredchess animations */

.animated-element {
    position: relative;
    opacity: 0;
    transition: 1.3s all ease;
}

.animate-right {transform: translateX(-100%);}
.animate-left {transform: translateX(100%);}
.animate-up {transform: translateY(-100%);}
.animate-down {transform: translateY(100%);}

.animate-bounce {
    animation-name: animate-bounce;
    animation-duration: 1s;
    animation-iteration-count: 1;
}

.animate-right.active, .animate-left.active {
    opacity: 1;
    transform: translateX(0);
}

.fe_animate-bob:hover {
    animation-name: animate-bob;
    animation-duration: 1.5s;
    animation-delay: 0s,.3s;
    animation-timing-function: ease-out,ease-in-out;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
    animation-direction: normal,alternate;
}

.animate-up.active, .animate-down.active {
    opacity: 1;
    transform: translateY(0);
}

.animate-bounce.active {
    transform: translateY(0);
    opacity: 1;
}

@keyframes load-bar {
    from { width: 0 }
    to { width: 100% }
}

@keyframes animate-bob {
    0% {
        transform: translateY(-8px);
    }
    50% {
        transform: translateY(-4px);
    }
    100% {
        transform: translateY(-8px);
    }
}

@keyframes animate-bounce {
    0% {
        transform: translateY(100%);
    }
    50% {
        transform: translateY(-70%);
    }
    70% {
        transform: translateY(50%);
    }
    100% {
        transform: translateY(0);
    }
}
